import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { CheckCircle, CircleOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button, Divider, IconButton, Stack, Typography, useTheme } from '@mui/material';
import base64 from 'base-64';
import { observer } from 'mobx-react-lite';
import { RHFCheckbox, RHFTextField } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/FormProvider';
import Iconify from 'src/components/iconify';
import { Page } from 'src/components/Page';
import { LayoutProps } from 'src/layouts';
import { useStores } from 'src/models';
import { PATH_AUTH, PATH_PAGE } from 'src/routes/paths';
import * as Yup from 'yup';

import { SnsLogin } from './SnsLogin';

type FormValuesProps = {
  loginId: string;
  pwd: string;
  // afterSubmit?: string;
};
/**
 * ## Login 설명
 *
 */
const Login = observer(() => {
  const { t } = useTranslation();
  const { REACT_APP_APP_NAME } = process.env;
  const { authStore } = useStores();
  const { login, getUserInfo } = authStore;
  const theme = useTheme();
  const navigate = useNavigate();
  const { state } = useLocation();
  const PageTitle = t('accountOps.login.login').toUpperCase();
  const { setHeaderProps } = useOutletContext<LayoutProps>();
  useEffect(() => {
    if (setHeaderProps) {
      setHeaderProps({
        centerTitle: PageTitle,
        leftTypes: ['back'],
        rightTypes: ['home'],
      });
    }
  }, [setHeaderProps, PageTitle]);

  const defaultValues = {
    loginId:
      localStorage.getItem('rememberId') !== null && localStorage.getItem('rememberId') !== ''
        ? base64.decode(localStorage.getItem('rememberId') || '')
        : '',
    pwd: '',
  };

  const LoginSchema = Yup.object().shape({
    loginId: Yup.string().required(t('accountOps.login.enterId')).trim(),
    pwd: Yup.string().required(t('accountOps.login.enterPw')),
  });

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    getValues,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;

  const [showPassword, setShowPassword] = useState(false);
  const [check, setCheck] = useState<boolean>(
    JSON.parse(localStorage.getItem('rememberMe') || 'true'),
  );

  const onSubmit = async (data: FormValuesProps) => {
    if (check === true && getValues('loginId').length > 0) {
      localStorage.setItem('rememberId', base64.encode(getValues('loginId')));
    } else {
      localStorage.removeItem('rememberId');
    }
    localStorage.removeItem('auth-errors');

    try {
      const result = await login(data.loginId, data.pwd);
      if (result.success) {
        // submit 후 state의 from이 있으면 해당 페이지로 이동
        console.log(state);

        if (state && state.from) {
          navigate(state.from, { replace: true, state: null });
        } else {
          navigate(PATH_PAGE.myPage, { replace: true, state: null });
        }
        getUserInfo();
      } else {
        alert(result.message);
      }
    } catch (error) {
      console.error(error);
      reset();
      // setError('afterSubmit', {
      //   ...error,
      //   message: error.message,
      // });
    }
  };

  useEffect(() => {
    if (check === true) {
      localStorage.setItem('rememberMe', 'true');
    } else {
      localStorage.removeItem('rememberMe');
    }
  }, [check]);

  useEffect(() => {
    if (localStorage.getItem('rememberMe')) {
      setCheck(true);
    } else {
      setCheck(false);
    }
    localStorage.removeItem('rememberId');
    // navigate('', { replace: true, state: null }); // 회원가입시도 -> 이미 ID/PW 계정이 있는 경우 가져온 state reset
  }, []);

  return (
    <Page title={PageTitle}>
      <Stack mb={5}>
        <Typography variant="h3" textAlign={'left'}>
          <Trans i18nKey="accountOps.login.greeting" values={{ value: REACT_APP_APP_NAME }} />
        </Typography>
      </Stack>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack gap={2}>
          <RHFTextField name="loginId" type="email" placeholder={t('accountOps.login.enterId')} />
          <RHFTextField
            name="pwd"
            type={showPassword ? 'text' : 'password'}
            placeholder={t('accountOps.login.enterPw')}
            InputProps={{
              endAdornment: (
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end" sx={{ m: 0 }}>
                  <Iconify
                    color={theme.palette.grey[400]}
                    icon={showPassword ? 'basil:eye-outline' : 'basil:eye-closed-outline'}
                  />
                </IconButton>
              ),
            }}
          />
          <Stack direction={'row'}>
            <RHFCheckbox
              name={'rememberMe'}
              label={t('accountOps.login.rememberMe')}
              icon={<CircleOutlined />}
              checkedIcon={<CheckCircle />}
              checked={!!check}
              onClick={(e: any) => {
                if (e.nativeEvent.target.checked !== undefined) {
                  setCheck(e.nativeEvent.target.checked);
                }
              }}
              sx={{ mx: 1 }}
            />
          </Stack>
        </Stack>
        <LoadingButton
          fullWidth
          color="primary"
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          sx={{ my: 5 }}
        >
          {t('accountOps.login.login')}
        </LoadingButton>
      </FormProvider>

      <Stack
        direction={'row'}
        justifyContent={'space-evenly'}
        alignItems={'center'}
        sx={{ mb: 5, '& button': { fontWeight: 400 } }}
      >
        <Button color="inherit" variant="text" onClick={() => navigate(PATH_AUTH.findId)}>
          {t('accountOps.accountRecovery.findId')}
        </Button>
        <Iconify icon={'fluent:divider-short-16-regular'} color={theme.palette.grey[400]} />
        <Button color="inherit" variant="text" onClick={() => navigate(PATH_AUTH.findPw)}>
          {t('accountOps.accountRecovery.findPw')}
        </Button>
        <Iconify icon={'fluent:divider-short-16-regular'} color={theme.palette.grey[400]} />
        <Button color="inherit" variant="text" onClick={() => navigate(PATH_AUTH.register)}>
          {t('accountOps.signUp.signUp')}
        </Button>
      </Stack>

      <Stack direction={'row'} alignItems={'center'} gap={3} color={theme.palette.text.disabled}>
        <Divider sx={{ flex: 1 }} />
        <Typography>{t('accountOps.login.or')}</Typography>
        <Divider sx={{ flex: 1 }} />
      </Stack>

      <SnsLogin />
    </Page>
  );
});

export default Login;
