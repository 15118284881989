import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { alpha, Divider, DividerProps, Stack, styled, Typography, useTheme } from '@mui/material';
import { ReactComponent as AppStore } from 'src/assets/images/app-store.svg';
import { ReactComponent as GooglePlay } from 'src/assets/images/google-play.svg';
import { PATH_PAGE, SUB_LINK } from 'src/routes/paths';
import { termsApi } from 'src/services';

function CompanyInfo() {
  const { REACT_APP_APP_NAME } = process.env;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();

  const handleClickTerms = async (type: number) => {
    // type 1: 이용약관 type 2: 개인정보 처리방침 type 3: 사업자정보확인 type 4: 전자금융거래약관
    await termsApi.getTerms(type).then((res) => navigate(PATH_PAGE.terms, { state: res }));
  };

  return (
    <Container>
      <Stack flexDirection="column" gap={15}>
        <Stack flexDirection="column" gap={7.5}>
          <Typography
            fontSize={30}
            lineHeight={'100%'}
            fontWeight={500}
            color={theme.palette.primary.main}
          >
            {/* {t('main.slogan')} */}
            <Trans i18nKey="main.slogan" />
          </Typography>
          <Typography
            fontSize={80}
            lineHeight={'100%'}
            fontWeight={600}
            color={theme.palette.primary.main}
          >
            {REACT_APP_APP_NAME}
          </Typography>
        </Stack>
        <Stack flexDirection="row" gap={2.5}>
          <AppStore />
          <GooglePlay />
        </Stack>
      </Stack>
      <Stack flexDirection="column" gap={7.5} flexGrow={1} justifyContent="flex-end">
        <Stack flexDirection="row" gap={4}>
          <Typography
            fontSize={16}
            lineHeight="150%"
            fontWeight={600}
            color={theme.palette.common.white}
            onClick={() => handleClickTerms(1)}
            sx={{ cursor: 'pointer' }}
          >
            {t('main.footer.terms')}
          </Typography>
          <Typography
            fontSize={16}
            lineHeight="150%"
            fontWeight={600}
            color={theme.palette.common.white}
            onClick={() => handleClickTerms(2)}
            sx={{ cursor: 'pointer' }}
          >
            {t('main.footer.privacy')}
          </Typography>
          <Typography
            fontSize={16}
            lineHeight="150%"
            fontWeight={600}
            color={theme.palette.common.white}
            onClick={() => navigate(PATH_PAGE.userGuide)}
            sx={{ cursor: 'pointer' }}
          >
            {t('main.footer.guide')}
          </Typography>
          <Typography
            fontSize={16}
            lineHeight="150%"
            fontWeight={600}
            color={theme.palette.common.white}
            onClick={() => navigate(SUB_LINK.faq)}
            sx={{ cursor: 'pointer' }}
          >
            FAQ
          </Typography>
        </Stack>
        <Stack flexDirection="column" gap={2.5}>
          <Stack flexDirection="row" gap={2.5}>
            <Typography fontSize={14} lineHeight="150%" color={theme.palette.grey[400]}>
              {REACT_APP_APP_NAME}
            </Typography>
            <StyledDivider />
            <Typography fontSize={14} lineHeight="150%" color={theme.palette.grey[400]}>
              {t('main.footer.ceo')}
            </Typography>
            <StyledDivider />
            <Typography fontSize={14} lineHeight="150%" color={theme.palette.grey[400]}>
              {t('main.footer.addr')}
            </Typography>
          </Stack>
          <Stack flexDirection="row" gap={2.5}>
            <Typography fontSize={14} lineHeight="150%" color={theme.palette.grey[400]}>
              {t('main.footer.cs')}
            </Typography>
            <StyledDivider />
            <Typography fontSize={14} lineHeight="150%" color={theme.palette.grey[400]}>
              {t('main.footer.fax')}
            </Typography>
            <StyledDivider />
            <Typography fontSize={14} lineHeight="150%" color={theme.palette.grey[400]}>
              {t('main.footer.email')}
            </Typography>
          </Stack>
          <Stack flexDirection="row" gap={2.5}>
            <Typography fontSize={14} lineHeight="150%" color={theme.palette.grey[400]}>
              {t('main.footer.bizNum')}{' '}
              <span
                onClick={() => handleClickTerms(3)}
                style={{ fontWeight: 600, cursor: 'pointer' }}
              >
                [{t('main.footer.bizInfo')}]
              </span>
            </Typography>
          </Stack>
          <Stack flexDirection="row" gap={2.5}>
            <Typography fontSize={14} lineHeight="150%" color={theme.palette.grey[400]}>
              {t('main.footer.eReport')}
            </Typography>
            <StyledDivider />
            <Typography fontSize={14} lineHeight="150%" color={theme.palette.grey[400]}>
              {t('main.footer.privacyMgr')}
            </Typography>
          </Stack>
        </Stack>
        <Stack>
          <Typography fontSize={14} lineHeight="150%" color={theme.palette.grey[400]}>
            ⓒ{REACT_APP_APP_NAME}, Inc. All Rights Reserved.
          </Typography>
        </Stack>
      </Stack>
    </Container>
  );
}

export default CompanyInfo;

const Container = styled('aside')(({ theme }) => ({
  height: '100vh',
  display: 'flex',
  flexBasis: '750px',
  flexDirection: 'column',
  overflow: 'hidden',
  paddingBottom: '40px',
  position: 'sticky',
  top: 0,
  zIndex: 1,
  padding: '120px 0',
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
  borderColor: alpha(theme.palette.common.white, 0.16),
})) as typeof Divider & {
  defaultProps: Partial<DividerProps>;
};

StyledDivider.defaultProps = {
  orientation: 'vertical',
  variant: 'middle',
  flexItem: true,
};
