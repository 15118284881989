import { useCallback, useEffect } from 'react';
import { ReceiveMessageType } from 'src/@types/message';
import useWebViewCommunication from 'src/hooks/useWebViewCommunication';
import { useStores } from 'src/models';

function AppMessageListener() {
  const { appInfoStore } = useStores();
  // handle message from RN
  const handleRNMessage = useCallback(
    (message: ReceiveMessageType) => {
      switch (message.type) {
        case 'APP_INFO':
          appInfoStore.setProp('safeAreaInsets', message.payload.safeAreaInsets);
          break;
      }
    },
    [appInfoStore],
  );

  const { sendMessage } = useWebViewCommunication(handleRNMessage);

  useEffect(() => {
    sendMessage({ type: 'INIT' });
  }, [sendMessage]);

  return null;
}

export default AppMessageListener;
