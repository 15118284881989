import { SVGProps } from 'react';

interface Props extends SVGProps<SVGSVGElement> {}

const ThumbsUpIcon = (props: Props) => (
  <svg
    width={13}
    height={13}
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_410_7198)">
      <g>
        <path
          d="M7.90866 4.3333H11.3753C11.6626 4.3333 11.9382 4.44744 12.1414 4.6506C12.3445 4.85377 12.4587 5.12932 12.4587 5.41664V6.5563C12.4588 6.69787 12.4312 6.8381 12.3774 6.96905L10.7009 11.0397C10.6601 11.1389 10.5906 11.2238 10.5014 11.2835C10.4122 11.3432 10.3072 11.375 10.1999 11.375H1.08366C0.94 11.375 0.802225 11.3179 0.700643 11.2163C0.59906 11.1147 0.541992 10.977 0.541992 10.8333V5.41664C0.541992 5.27298 0.59906 5.1352 0.700643 5.03362C0.802225 4.93204 0.94 4.87497 1.08366 4.87497H2.96974C3.05646 4.87499 3.14192 4.85419 3.21893 4.81432C3.29594 4.77445 3.36225 4.71667 3.41228 4.64584L6.36599 0.460385C6.40333 0.407471 6.45839 0.369688 6.5212 0.353888C6.584 0.338087 6.65039 0.345315 6.70832 0.37426L7.69091 0.865552C7.96743 1.00376 8.18834 1.23243 8.31693 1.51356C8.44552 1.79468 8.47405 2.11134 8.39778 2.41093L7.90866 4.3333ZM3.79199 5.73514V10.2916H9.83699L11.3753 6.5563V5.41664H7.90866C7.74367 5.41661 7.58086 5.3789 7.43266 5.30638C7.28446 5.23386 7.15478 5.12845 7.05352 4.99819C6.95225 4.86793 6.88208 4.71626 6.84835 4.55475C6.81463 4.39324 6.81824 4.22616 6.85891 4.06626L7.34803 2.14443C7.36334 2.08448 7.35766 2.0211 7.33194 1.96483C7.30622 1.90855 7.26201 1.86279 7.20666 1.83513L6.84862 1.65638L4.29737 5.27038C4.16195 5.46213 3.98862 5.61922 3.79199 5.73514ZM2.70866 5.9583H1.62533V10.2916H2.70866V5.9583Z"
          fill="#AAAAAA"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_410_7198">
        <rect width={13} height={13} fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default ThumbsUpIcon;
