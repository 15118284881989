import { SVGProps } from 'react';

interface Props extends SVGProps<SVGSVGElement> {}

const SuccessIcon = (props: Props) => (
  <svg
    width={71}
    height={70}
    viewBox="0 0 71 70"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g>
      <rect x={0.640625} width={70} height={70} rx={35} fill="url(#paint0_linear_879_5028)" />
      <g>
        <path
          d="M30.6415 41.95L24.8582 36.1667C24.5466 35.855 24.1239 35.68 23.6832 35.68C23.2425 35.68 22.8198 35.855 22.5082 36.1667C22.1966 36.4783 22.0215 36.901 22.0215 37.3417C22.0215 37.5599 22.0645 37.776 22.148 37.9776C22.2315 38.1792 22.3539 38.3624 22.5082 38.5167L29.4749 45.4833C30.1249 46.1333 31.1749 46.1333 31.8249 45.4833L49.4582 27.85C49.7698 27.5384 49.9449 27.1157 49.9449 26.675C49.9449 26.2343 49.7698 25.8116 49.4582 25.5C49.1466 25.1884 48.7239 25.0133 48.2832 25.0133C47.8425 25.0133 47.4198 25.1884 47.1082 25.5L30.6415 41.95Z"
          fill="white"
        />
      </g>
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_879_5028"
        x1={28.5726}
        y1={33.973}
        x2={41.5727}
        y2={83.3263}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F0ABDF" />
        <stop offset={1} stopColor="#F6D9BB" />
      </linearGradient>
    </defs>
  </svg>
);

export default SuccessIcon;
