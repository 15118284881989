import { useNavigate } from 'react-router-dom';
import { AppBar, AppBarProps, Badge, styled, Toolbar } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { observer } from 'mobx-react-lite';
import {
  AlarmIcon,
  CartIcon,
  CloseIcon,
  GobackIcon,
  HomeIcon,
  SearchIcon,
  SettingIcon,
} from 'src/assets/icons';
import { HEADER_HEIGHT, MOBILE_MAX_WIDTH } from 'src/assets/static/constants';
import Logo from 'src/components/logo';
import { useStores } from 'src/models';
import { PATH_PAGE, PATH_SUB, SUB_LINK } from 'src/routes/paths';

type LeftType = 'back' | 'logo';
type RightType = 'home' | 'setting' | 'cart' | 'search' | 'close' | 'alarm';
type CenterType = 'logo' | 'title';

export interface HeaderProps extends AppBarProps {
  leftTypes?: LeftType[] | 'none';
  centerType?: CenterType;
  centerTitle?: string;
  rightTypes?: RightType[] | 'none';
  onClickLeft?: (type: LeftType) => void;
  onClickCenter?: () => void;
  onClickRight?: (type: RightType) => void;
  customLeft?: React.ReactNode;
  customCenter?: React.ReactNode;
  customRight?: React.ReactNode;
}

const Header = ({
  leftTypes = 'none',
  centerType = 'title',
  centerTitle = '',
  rightTypes = 'none',
  onClickLeft,
  onClickCenter,
  onClickRight,
  customLeft,
  customCenter,
  customRight,
  ...props
}: HeaderProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { appInfoStore, cartStore, snackbarStore } = useStores();

  const renderIcon = (type: LeftType | RightType) => {
    switch (type) {
      case 'back':
        return <GobackIcon />;
      case 'home':
        return <HomeIcon />;
      case 'setting':
        return <SettingIcon />;
      case 'cart':
        return (
          <Badge
            badgeContent={cartStore.totalAmount}
            color="primary"
            invisible={cartStore.totalAmount === 0}
          >
            <CartIcon />
          </Badge>
        );
      case 'search':
        return <SearchIcon />;
      case 'close':
        return <CloseIcon />;
      case 'logo':
        return <Logo sx={{ marginLeft: 2 }} />;
      case 'alarm':
        return <AlarmIcon />;
      default:
        return null;
    }
  };

  const handleClickRight = (type: RightType) => {
    if (onClickRight) {
      onClickRight(type);
      return;
    }

    switch (type) {
      case 'home':
        return navigate(PATH_SUB.home);
      case 'setting':
        return navigate(SUB_LINK.setting.root);
      case 'cart':
        return navigate(PATH_PAGE.cart);
      case 'alarm':
        // TODO: 기능 구현 시 적용
        snackbarStore.setProps({
          open: true,
          title: '알림사항이 없습니다.',
        });
        return;
    }
  };

  const handleClickLeft = (type: LeftType) => {
    if (onClickLeft) {
      onClickLeft(type);
      return;
    }

    switch (type) {
      case 'back':
        return navigate(-1);
    }
  };

  const renderLeft = () => {
    if (leftTypes === 'none') return null;

    if (customLeft) {
      return customLeft;
    }

    return (
      <>
        {leftTypes.map((type) => {
          return (
            <IconWrapper
              key={`left-menu-${type}`}
              onClick={() => {
                handleClickLeft(type);
              }}
              sx={{ cursor: 'pointer' }}
            >
              {renderIcon(type)}
            </IconWrapper>
          );
        })}
      </>
    );
  };

  const renderRight = () => {
    if (rightTypes === 'none') return null;

    if (customRight) {
      return customRight;
    }

    return (
      <>
        {rightTypes.map((type) => {
          return (
            <IconWrapper
              key={`right-menu-${type}`}
              onClick={() => {
                handleClickRight(type);
              }}
              sx={{ cursor: 'pointer' }}
            >
              {renderIcon(type)}
            </IconWrapper>
          );
        })}
      </>
    );
  };

  const renderCenter = () => {
    if (customCenter) {
      return customCenter;
    }

    switch (centerType) {
      case 'title':
        return <TitleText>{centerTitle}</TitleText>;
      default:
        return null;
    }
  };

  const handleClickCenter = () => {
    if (onClickCenter) {
      onClickCenter();
      return;
    }

    switch (centerType) {
      default:
        return null;
    }
  };

  return (
    <Container safeAreaInsetsTop={appInfoStore.safeAreaInsets.top} {...props}>
      <HeaderContentContainer>
        {/* Left */}
        <LeftItem id="header-left-menu">{renderLeft()}</LeftItem>
        {/* Center */}
        <CenterItem
          onClick={handleClickCenter}
          sx={{ cursor: onClickCenter ? 'pointer' : 'default' }}
        >
          {renderCenter()}
        </CenterItem>
        {/* Right */}
        <RightItem id="header-right-menu">{renderRight()}</RightItem>
      </HeaderContentContainer>
    </Container>
  );
};

export default observer(Header);

const Container = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'safeAreaInsetsTop',
})<{ safeAreaInsetsTop: number }>(({ theme, safeAreaInsetsTop }) => ({
  backgroundColor: theme.palette.background.paper,
  paddingTop: safeAreaInsetsTop,
  boxShadow: 'none',
  transition: theme.transitions.create(['backgroundColor', 'height', 'top'], {
    duration: theme.transitions.duration.shortest,
    easing: theme.transitions.easing.easeInOut,
  }),
  position: 'sticky',
  top: 0,
}));

const HeaderContentContainer = styled(Toolbar)(({ theme }) => ({
  height: `${HEADER_HEIGHT}px`,
  width: '100%',
  maxWidth: MOBILE_MAX_WIDTH,
  margin: 'auto',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(3),
}));

const Item = styled('div')({
  minWidth: 40,
  minHeight: 40,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  userSelect: 'none',
  WebkitTapHighlightColor: 'transparent',
});

const LeftItem = styled(Item)({});

const CenterItem = styled(Item)({
  position: 'absolute',
  left: '50%',
  transform: 'translateX(-50%)',
});

const RightItem = styled(Item)({});

const TitleText = styled('h3')(({ theme }) => ({
  fontSize: '20px',
  lineHeight: '100%',
  fontWeight: 500,
  textAlign: 'center',
  color: theme.palette.text.primary,
  margin: 0,
}));

const IconWrapper = styled('div')({
  minWidth: 40,
  minHeight: 40,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  userSelect: 'none',
});
