import { SVGProps } from 'react';

interface Props extends SVGProps<SVGSVGElement> {}

const CheckIcon = (props: Props) => (
  <svg
    width={20}
    height={21}
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.6673 5.5L7.50065 14.6667L3.33398 10.5"
      stroke="#DDDDDD"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CheckIcon;
