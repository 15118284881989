import { createBrowserRouter, Navigate, RouteObject } from 'react-router-dom';
import AuthGuard from 'src/auth/AuthGuard';
import GuestGuard from 'src/auth/GuestGuard';
import { MobileLayout } from 'src/layouts';

import {
  AccountRecovery,
  AlreadyExist,
  Cart,
  ChangePw,
  ComingSoon,
  Coupons,
  Events,
  FaQ,
  FindId,
  FindPw,
  Goods,
  Home,
  Inquiry,
  Login,
  MakeInquiry,
  MyInfo,
  MyPage,
  MyQnA,
  MyReview,
  NoticeDetail,
  Notices,
  NotificationSettings,
  OrderDetails,
  OrderList,
  Page403,
  Page404,
  PaymentOrder,
  PaymentOrderSuccess,
  Points,
  Register,
  Results,
  Setting,
  Shop,
  Survey,
  Terms,
  UserGuide,
  Withdrawal,
} from './elements';
import { PATH_AUTH, PATH_PAGE, PATH_SUB, SUB_LINK } from './paths';

const routes: RouteObject[] = [
  {
    element: <MobileLayout />,
    children: [
      {
        path: PATH_SUB.root,
        element: <Home />,
      },
      {
        path: PATH_PAGE.shop,
        element: <Shop />,
      },
      {
        path: PATH_PAGE.results,
        element: <Results />,
      },
      {
        path: PATH_PAGE.events,
        element: <Events />,
      },
      {
        path: PATH_PAGE.myPage,
        element: (
          <AuthGuard>
            <MyPage />
          </AuthGuard>
        ),
      },
    ],
  },
  {
    element: <MobileLayout needFooter={false} />,
    children: [
      {
        path: PATH_AUTH.accountRecovery,
        element: (
          <GuestGuard>
            <AccountRecovery />
          </GuestGuard>
        ),
      },
      {
        path: PATH_AUTH.findId,
        element: (
          <GuestGuard>
            <FindId />
          </GuestGuard>
        ),
      },
      {
        path: PATH_AUTH.findPw,
        element: (
          <GuestGuard>
            <FindPw />
          </GuestGuard>
        ),
      },
      {
        path: PATH_AUTH.register,
        element: (
          <GuestGuard>
            <Register />
          </GuestGuard>
        ),
      },
      {
        path: PATH_AUTH.existId,
        element: (
          <GuestGuard>
            <AlreadyExist />
          </GuestGuard>
        ),
      },
      {
        path: PATH_PAGE.goods,
        element: <Goods />,
      },
      {
        path: PATH_PAGE.cart,
        element: (
          <AuthGuard>
            <Cart />
          </AuthGuard>
        ),
      },
      {
        path: PATH_PAGE.paymentOrder,
        element: <PaymentOrder />,
      },
      {
        path: PATH_PAGE.paymentOrderSuccess,
        element: <PaymentOrderSuccess />,
      },
      {
        path: PATH_PAGE.terms,
        element: <Terms />,
      },
      {
        path: PATH_PAGE.userGuide,
        element: <UserGuide />,
      },
      {
        path: PATH_PAGE.myPage,
        children: [
          {
            path: SUB_LINK.faq,
            element: <FaQ />,
          },
          {
            path: SUB_LINK.qna.root,
            element: <MyQnA />,
          },
          {
            path: SUB_LINK.notices.root,
            element: <Notices />,
          },
          {
            path: SUB_LINK.notices.detail,
            element: <NoticeDetail />,
          },
          {
            path: SUB_LINK.inquiry.root,
            element: (
              <AuthGuard>
                <Inquiry />
              </AuthGuard>
            ),
          },
          {
            path: SUB_LINK.inquiry.create,
            element: (
              <AuthGuard>
                <MakeInquiry />
              </AuthGuard>
            ),
          },
          {
            path: SUB_LINK.setting.root,
            element: (
              <AuthGuard>
                <Setting />
              </AuthGuard>
            ),
          },
          {
            path: SUB_LINK.setting.myInfo,
            element: (
              <AuthGuard>
                <MyInfo />
              </AuthGuard>
            ),
          },
          {
            path: SUB_LINK.order.list,
            element: (
              <AuthGuard>
                <OrderList />
              </AuthGuard>
            ),
          },
          {
            path: SUB_LINK.order.details,
            element: (
              <AuthGuard>
                <OrderDetails />
              </AuthGuard>
            ),
          },
          {
            path: SUB_LINK.point.root,
            element: (
              <AuthGuard>
                <Points />
              </AuthGuard>
            ),
          },
          {
            path: SUB_LINK.coupon.root,
            element: (
              <AuthGuard>
                <Coupons />
              </AuthGuard>
            ),
          },
          {
            path: SUB_LINK.review.root,
            element: (
              <AuthGuard>
                <MyReview />
              </AuthGuard>
            ),
          },
          {
            path: SUB_LINK.survey.root,
            element: (
              <AuthGuard>
                <Survey />
              </AuthGuard>
            ),
          },
        ],
      },
      {
        path: PATH_AUTH.login,
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        ),
      },
      {
        path: PATH_AUTH.changePw,
        element: <ChangePw />,
      },
      {
        path: SUB_LINK.setting.notification,
        element: <NotificationSettings />,
      },
      {
        path: PATH_AUTH.withdrawal,
        element: <Withdrawal />,
      },
      {
        path: PATH_PAGE.page404,
        element: <Page404 />,
      },
      {
        path: PATH_PAGE.page403,
        element: <Page403 />,
      },
      {
        path: PATH_PAGE.comingSoon,
        element: <ComingSoon />,
      },
      { path: '*', element: <Navigate to={PATH_PAGE.page404} replace /> },
    ],
  },
];

const router = createBrowserRouter(routes);

export default router;
