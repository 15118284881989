function path(root: string, sublink: string) {
  return `${root}/${sublink}`.replace('///', '/').replace('//', '/');
}

const ROOT = '/';
const ROOT_PATH = path(ROOT, '');

export const PATH_SUB = {
  root: ROOT_PATH,
  home: path(ROOT_PATH, '/'),
};

export const PATH_AUTH = {
  root: path(ROOT_PATH, 'auth'),
  login: path(ROOT_PATH, 'login'),
  register: path(ROOT_PATH, 'register'),
  existId: path(ROOT_PATH, 'existId'),
  accountRecovery: path(ROOT_PATH, 'account-recovery'),
  findId: path(ROOT_PATH, 'find-id'),
  findPw: path(ROOT_PATH, 'find-pw'),
  withdrawal: path(ROOT_PATH, 'withdrawal'),
  changePw: path(ROOT_PATH, 'changePw'),
};

export const PATH_PAGE = {
  shop: path(ROOT_PATH, 'shop'),
  results: path(ROOT_PATH, 'results'),
  events: path(ROOT_PATH, 'events'),
  myPage: path(ROOT_PATH, 'my-page'),
  page403: path(ROOT_PATH, '403'),
  page404: path(ROOT_PATH, '404'),
  page500: path(ROOT_PATH, '500'),
  comingSoon: path(ROOT_PATH, 'coming-soon'),
  maintenance: path(ROOT_PATH, 'maintenance'),
  goods: path(ROOT_PATH, 'goods/:goodsSid'),
  cart: path(ROOT_PATH, 'cart'),
  terms: path(ROOT_PATH, 'terms'),
  userGuide: path(ROOT_PATH, 'user-guide'),
  paymentOrder: path(ROOT_PATH, 'payment-order'),
  paymentOrderSuccess: path(ROOT_PATH, 'payment-order/success/:orderNo'),
  // terms: {
  //   root: path(ROOT_PATH, 'terms'),
  //   termsOfService: path(path(ROOT_PATH, 'terms'), 'terms-of-service'),
  //   privacyPolicy: path(path(ROOT_PATH, 'terms'), 'privacy-policy'),
  //   businessDetails: path(path(ROOT_PATH, 'terms'), 'business-details'),
  // },
};

export const SUB_LINK = {
  faq: path(PATH_PAGE.myPage, 'faq'),
  order: {
    list: path(path(PATH_PAGE.myPage, 'order'), 'list'),
    details: path(path(PATH_PAGE.myPage, 'order'), 'details/:orderNo'),
  },
  review: {
    root: path(PATH_PAGE.myPage, 'review'),
  },
  notices: {
    root: path(PATH_PAGE.myPage, 'notices'),
    detail: path(path(PATH_PAGE.myPage, 'notices'), 'detail/:noticeSid'),
  },
  inquiry: {
    root: path(PATH_PAGE.myPage, 'inquiry'),
    create: path(PATH_PAGE.myPage, 'make-inquiry'),
  },
  qna: {
    root: path(PATH_PAGE.myPage, 'qna'),
  },
  point: {
    root: path(PATH_PAGE.myPage, 'point'),
  },
  coupon: {
    root: path(PATH_PAGE.myPage, 'coupon'),
  },
  survey: {
    root: path(PATH_PAGE.myPage, 'survey'),
  },
  setting: {
    root: path(PATH_PAGE.myPage, 'setting'),
    myInfo: path(PATH_PAGE.myPage, 'my-info'),
    notification: path(PATH_PAGE.myPage, 'notification-settings'),
  },
};
