import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { BottomNavigation, BottomNavigationAction, Paper, styled, useTheme } from '@mui/material';
import { observer } from 'mobx-react-lite';
// import { ReactComponent as IcoCartActive } from 'src/assets/icons/footerIcon/cartActive.svg';
// import { ReactComponent as IcoCartInactive } from 'src/assets/icons/footerIcon/cartInactive.svg';
// import { ReactComponent as IcoEventActive } from 'src/assets/icons/footerIcon/eventActive.svg';
// import { ReactComponent as IcoEventInactive } from 'src/assets/icons/footerIcon/eventInactive.svg';
import { ReactComponent as IcoHomeActive } from 'src/assets/icons/footerIcon/homeActive.svg';
import { ReactComponent as IcoHomeInactive } from 'src/assets/icons/footerIcon/homeInactive.svg';
import { ReactComponent as IcoMarketActive } from 'src/assets/icons/footerIcon/marketActive.svg';
import { ReactComponent as IcoMarketInactive } from 'src/assets/icons/footerIcon/marketInactive.svg';
import { ReactComponent as IcoMypageActive } from 'src/assets/icons/footerIcon/mypageActive.svg';
import { ReactComponent as IcoMypageInactive } from 'src/assets/icons/footerIcon/mypageInactive.svg';
import { ReactComponent as IcoResultActive } from 'src/assets/icons/footerIcon/resultActive.svg';
import { ReactComponent as IcoResultInactive } from 'src/assets/icons/footerIcon/resultInactive.svg';
import { FOOTER_HEIGHT, MOBILE_MAX_WIDTH } from 'src/assets/static/constants';
import { useStores } from 'src/models';
import { PATH_PAGE, PATH_SUB } from 'src/routes/paths';

// -----------------------------------------------------------------

function Footer() {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const { appInfoStore } = useStores();
  const [value, setValue] = useState<number>(-1);
  const navigate = useNavigate();
  const theme = useTheme();
  const BottomNavs = [
    {
      label: t('bttmNav.home'),
      pathname: PATH_SUB.root,
      activatedIcon: <IcoHomeActive />,
      deactivatedIcon: <IcoHomeInactive />,
    },
    {
      label: t('bttmNav.market'),
      pathname: PATH_PAGE.shop,
      activatedIcon: <IcoMarketActive />,
      deactivatedIcon: <IcoMarketInactive />,
    },
    {
      label: t('bttmNav.result'),
      pathname: PATH_PAGE.results,
      activatedIcon: <IcoResultActive />,
      deactivatedIcon: <IcoResultInactive />,
    },
    // {
    //   label: '장바구니',
    //   pathname: PATH_PAGE.cart,
    //   activatedIcon: <IcoCartActive />,
    //   deactivatedIcon: <IcoCartInactive />,
    // },
    // {
    //   label: t('bttmNav.event'),
    //   pathname: PATH_PAGE.events,
    //   activatedIcon: <IcoEventActive />,
    //   deactivatedIcon: <IcoEventInactive />,
    // },
    {
      label: t('bttmNav.myPage'),
      pathname: PATH_PAGE.myPage,
      activatedIcon: <IcoMypageActive />,
      deactivatedIcon: <IcoMypageInactive />,
    },
  ];

  return (
    <Paper sx={{ position: 'sticky', bottom: 0, left: 0, right: 0 }} elevation={0}>
      <StyledFooter
        safeAreaInsetsBottom={appInfoStore.safeAreaInsets.bottom}
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      >
        <MenuContainer>
          {BottomNavs.map((v, i) => {
            return (
              <BottomNavigationAction
                key={i}
                label={v.label}
                value={v.pathname}
                onClick={() => navigate(v.pathname)}
                icon={pathname === v.pathname ? v.activatedIcon : v.deactivatedIcon}
                showLabel
              />
            );
          })}
        </MenuContainer>
      </StyledFooter>
    </Paper>
  );
}

export default observer(Footer);

const StyledFooter = styled(BottomNavigation, {
  shouldForwardProp: (prop) => prop !== 'safeAreaInsetsBottom',
})<{ safeAreaInsetsBottom: number }>(({ safeAreaInsetsBottom }) => ({
  height: `${FOOTER_HEIGHT + safeAreaInsetsBottom}px`,
  paddingBottom: `${safeAreaInsetsBottom}px`,
  boxShadow: '0px -5px 10px 0px #0000000D',
}));

const MenuContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  maxWidth: MOBILE_MAX_WIDTH,
}));
