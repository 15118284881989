import { IPayment } from 'src/@types/apiResponse';

export const MOBILE_MAX_WIDTH = 450;
export const MOBILE_FIXED_WIDTH = 450;
export const PAGE_PADDING = 20;
export const HEADER_HEIGHT = 64;
export const FOOTER_HEIGHT = 70;
export const TAB_HEIGHT = 48;
export const BOTTOM_BTN_HEIGHT = 80;
export const MULTI_BOTTOM_BTN_HEIGHT = 148;

export const COMMON_CODE_NAME = {
  GOODS_ORDER_TYPE_CD: 'GOODS_ORDER_TYPE_CD', // 상품 정렬 타입 코드
  ORDER_STATE_CD: 'ORDER_STATE_CD', // 주문 상태 코드
  INQUIRY_TYPE_CD: 'INQUIRY_TYPE_CD', // 문의 유형 코드
};

// 상품 정렬 타입 코드
export const GOODS_ORDER_TYPE_CODE = {
  SELL_COUNT: 230001, // 판매량 많은 순
  LOW_PRICE: 230002, // 낮은 가격 순
  HIGH_PRICE: 230003, // 높은 가격 순
  REVIEW_COUNT: 230004, // 리뷰 많은 순
};

// 약관 사용 유형 코드
export const TERMS_USE_LOC_CODE = {
  SIGN_UP: 820002, // 회원가입
  KIT_RETURN: 820003, // 키트 반송
  PAYMENT: 820004, // 결제
  MENU: 820005, // 메뉴
  AGREEMENT_INSPECTION: 820006, // 검사 동의서
  AGREEMENT_DATA_STORAGE: 820007, // 데이터 보관 및 추가 분석 동의서
};

// 주문 상태 코드
export const ORDER_STATE_CODE = {
  ORDER_COMPLETE: 210101, // 주문완료
  PAYMENT_WAIT: 210102, // 결제대기
  PAYMENT_COMPLETE: 210103, // 결제완료
  GOODS_PREPARING: 210115, // 상품 준비중
  DELIVERY_PREPARING: 210104, // 배송 준비중
  DELIVERY: 210105, // 배송중
  DELIVERY_COMPLETE: 210106, // 배송완료
  RETURN_REQUEST: 210107, // 반송요청
  RETURN: 210108, // 반송중
  RETURN_COMPLETE: 210109, // 반송완료
  ANALYSIS_IMPOSSIBLE: 210116, // 분석 불가
  ANALYSIS_REQUEST: 210110, // 분석요청
  ANALYSIS: 210111, // 분석중
  ANALYSIS_COMPLETE: 210112, // 분석완료
  ANALYSIS_FAIL: 210113, // 분석실패
  CANCEL_COMPLETE: 210114, // 취소완료
};

// 문의 유형 코드
export const INQUIRY_TYPE_CODE = {
  GOODS: 840007, // 상품
};

// 상품 타입 코드
export const GOODS_TYPE_CODE = {
  ANALYSIS: 230101, // 겸사살품
  NORMAL: 230102, // 배송상품
};

export const INIT_PAYMENT: IPayment = {
  totPriceAmt: 0,
  totGoodsAmt: 0,
  totDscntAmt: 0,
  totPaymentAmt: 0,
  totCpnDscntAmt: 0,
  cpnDscntList: [],
  currencyCd: {
    code: 200001,
    value: '원',
    pcode: 2000,
  },
};

// Group Type Code - 공통 코드 그룹 타입 코드
export const GROUP_TYPE_CODE = {
  WITHDRAWAL_REASON: 900501,
  DELIVERY_REQUEST: 900502,
  CANCEL_REASON: 900503,
  EXCHANGE_REASON: 900504,
};

// 배송 요청 코드
export const DELIVERY_REQUEST_CODE = {
  OTHER: 99, // 기타
};

// 쿠폰 할인 타입 코드
export const COUPON_DISCOUNT_TYPE_CODE = {
  PERCENT: 20101, // 할인울
  AMOUNT: 20102, // 할인금액
};
