import { useEffect } from 'react';
import { ReceiveMessageType, SendMessageType } from 'src/@types/message';

declare global {
  interface DocumentEventMap {
    message: MessageEvent;
  }
}

type MessageHandler = (message: ReceiveMessageType) => void;

const useWebViewCommunication = (handler?: MessageHandler) => {
  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      try {
        if (!event.data || typeof event.data !== 'string') return;
        const message: ReceiveMessageType = JSON.parse(event.data);
        handler && handler(message);
      } catch (error) {
        // do nothing
      }
    };

    document.addEventListener('message', handleMessage); // For Android
    window.addEventListener('message', handleMessage); // For iOS

    return () => {
      document.removeEventListener('message', handleMessage);
      window.removeEventListener('message', handleMessage);
    };
  }, [handler]);

  const sendMessage = (message: SendMessageType) => {
    window.ReactNativeWebView?.postMessage(JSON.stringify(message));
  };

  return { sendMessage };
};

export default useWebViewCommunication;
