import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Stack } from '@mui/material';
import { isObject } from 'lodash';
import apple_imm from 'src/assets/images/apple.svg';
import google_imm from 'src/assets/images/google.svg';
import kakao_imm from 'src/assets/images/kakao.svg';
import naver_imm from 'src/assets/images/naver.svg';
import { useStores } from 'src/models';
import { PATH_AUTH, PATH_PAGE } from 'src/routes/paths';
// import { loginSns } from 'src/services/auth';

export const SnsLogin = () => {
  const { REACT_APP_API_URL } = process.env;
  const { authStore } = useStores();
  const { getUserInfo, loginSns } = authStore;
  const navigate = useNavigate();

  const snsLoginClick = (type: string) => {
    let W = 400;
    switch (type) {
      case 'naver':
        W = 600;
        break;
      case 'kakao':
        W = 400;
        break;
      case 'facebook':
        W = 600;
        break;
      case 'apple':
        W = 600;
        break;
      case 'google':
        W = 400;
        break;
    }
    // navigate('', {
    //   state: {
    //     snsType: type,
    //   },
    // });

    // sendReactNativeMessage({
    //   type: 'getOSType',
    //   payload: {},
    // });

    setTimeout(() => {
      const url = `${REACT_APP_API_URL}/common/v1/user/login/${type}`;
      //   if (userStore.os) {
      //     sendReactNativeMessage({
      //       type: type + 'Login',
      //       payload: {
      //         url: url,
      //       },
      //     });
      //   } else {
      window.open(url, '본인인증 중...', `width=${W},height=550`);
      //   }
    }, 100);
  };

  const listener = async (event: any) => {
    let data: any;
    // console.log('포스트메시지', event.data);
    try {
      if (isObject(event.data)) {
        return;
      }
      if (!isObject(event.data)) {
        data = JSON.parse(event.data);
      }
    } catch (e) {
      console.log('e', event, e);
    }
    // if (data.type === 'os') {
    //   return;
    // }

    /**
     * RN comm.
     */
    let res: any;

    // {"resultCode":"F","errorCode":"4004","errorMessage":"Not Found"}
    // from react native..............................................
    switch (data?.type) {
      // sns login webview
      case 'snsLoginSuccess':
        break;

      case 'appDirectLogin':
        // console.log('🌈 ~ listener ~ login from app : ', data.payload.result);
        // eslint-disable-next-line no-case-declarations
        let snsTypeCd = '';
        switch (data.payload?.type) {
          case 'kakao':
            snsTypeCd = '900001';
            break;
          case 'naver':
            snsTypeCd = '900002';
            break;
          case 'google':
            snsTypeCd = '900003';
            break;
          case 'facebook':
            snsTypeCd = '900004';
            break;
          case 'apple':
            snsTypeCd = '900005';
            break;
        }
        fetch(REACT_APP_API_URL + '/common/v1/user/login/app/sns', {
          method: 'POST',
          body: JSON.stringify({
            snsTypeCd: snsTypeCd,
            snsId: data.payload.result,
          }),
        })
          .then(async (r: any) => {
            const res = await r.json();
            console.log('sns login from app ', res);
            // 로그인 성공
            if (res.resultCode === 'S') {
              switch (res.statusCode) {
                case '2000':
                  console.log(
                    "case '2000': // SNS 로그인 -> 회원정보 없음 -> 회원가입",
                    data.payload,
                  );
                  // auth.logout();
                  // marketStore.cartStore.reset();
                  setTimeout(() => {
                    navigate(PATH_AUTH.register, {
                      state: {
                        snsKey: res.data,
                        loginType: 'app',
                        snsType: data.payload?.type,
                      },
                      replace: true,
                    });
                  }, 300);
                  break;
                // case '2200':
                //   console.log('// SNS 로그인 -> 본인인증 -> 가입된 계정 존재 -> 계정 통합 질문');
                //   navigate(PATH_AUTH.existId, {
                //     state: {
                //       snsType: data.payload.type,
                //     },
                //     replace: true,
                //   });
                //   break;
              }
            }
          })
          .catch((e) => {
            console.log('🌈 ~ listener ~ e:', e);
          });

        return;

        break;
    }
    // end from react native....

    // from window.open.....
    // console.log('WEB 포스트메시지', data);
    if (data.resultCode === 'S') {
      switch (data.statusCode) {
        case '1000': // 로그인 성공
          loginSns(data.data).then((res) => {
            // console.log('🚀🚀🚀 ~ authApi.loginSns ~ res:', res);
            if (res.success) {
              navigate(PATH_PAGE.myPage, { replace: true, state: null });
              getUserInfo();
            } else {
              alert(res.message);
            }
          });
          break;

        // case '1100': // 일반회원가입 -> 본인인증 -> 본인인증성공
        //   navigate(PATH_AUTH.register, { replace: true });
        //   break;
        // case '1101': // SNS 로그인 -> 회원가입 -> SNS 본인인증 -> 본인인증성공
        //   navigate(PATH_AUTH.register, { replace: true });
        //   break;

        case '2000': // SNS 로그인 -> 회원정보 없음 -> 회원가입
          console.log("case '2000': // SNS 로그인 -> 회원정보 없음 -> 회원가입", data);
          // auth.logout();
          setTimeout(() => {
            navigate(PATH_AUTH.register, {
              state: {
                snsKey: data.data,
                loginType: 'sns',
              },
            });
          }, 500);
          break;

        case '2100': // 일반회원가입 -> 본인인증 -> ID/PW 존재
          // navigate(PATH_AUTH.existId, { replace: true, state: null });
          break;
        case '2101': // 일반회원가입 -> 본인인증 -> SNS 로그인 정보만 있을 경우(ID/PW 값 없는 경우)
          break;
        case '2200': // SNS 로그인 -> 본인인증 -> 가입된 계정 존재 -> 계정 통합 질문
          break;

        default:
          // setOpenAlert(true);
          // setAlertMessage('Login 알 수 없는 오류가 발생했습니다.');
          break;
      }
    }
  };

  useEffect(() => {
    try {
      document.addEventListener('message', listener);
      window.addEventListener('message', listener);
    } catch (e) {
      console.log(e);
    }

    return () => {
      document.removeEventListener('message', listener);
      window.removeEventListener('message', listener);
    };
  }, []);
  // useEffect(() => {
  //   // if (state && state.snsLoginExec) {
  //   //   console.log('snsLoginExec : ', state.snsLoginExec);
  //   //   navigate('', { state: null });
  //   //   snsLogin(state.snsLoginExec);
  //   // }
  //   try {
  //     document.addEventListener('message', listener);
  //     window.addEventListener('message', listener);
  //   } catch (e) {
  //     return;
  //   }

  //   return () => {
  //     try {
  //       document.removeEventListener('message', listener);
  //       window.removeEventListener('message', listener);
  //     } catch (e) {
  //       return;
  //     }
  //   };
  // }, [listener]);

  return (
    <Stack
      direction={'row'}
      sx={{
        mt: 2,
        width: '100%',
        justifyContent: 'center',
        gap: 4,
      }}
    >
      <Box
        component={'img'}
        src={naver_imm}
        width={50}
        height={50}
        onClick={() => snsLoginClick('naver')}
        sx={{ cursor: 'pointer' }}
      />

      <Box
        component={'img'}
        src={kakao_imm}
        width={50}
        height={50}
        onClick={() => snsLoginClick('kakao')}
        sx={{ cursor: 'pointer' }}
      />

      <Box
        component={'img'}
        src={apple_imm}
        width={50}
        height={50}
        onClick={() => snsLoginClick('apple')}
        sx={{ cursor: 'pointer' }}
      />

      <Box
        component={'img'}
        src={google_imm}
        width={50}
        height={50}
        onClick={() => snsLoginClick('google')}
        sx={{ cursor: 'pointer' }}
      />
    </Stack>
  );
};
