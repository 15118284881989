import { TApiResponseData } from '../apiTypes';
import instance from '../axios';

// --------------------------------마이페이지--------------------------------
/**
 * 메인화면 조회
 */
export const getMain = async () => {
  try {
    const url = `/common/v1/main`;
    const response = await instance.get<TApiResponseData<any>>(url);

    if (response.data.resultCode === 'S' && response.data.data) {
      return response.data.data;
    }
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
