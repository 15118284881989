import { Dispatch, forwardRef, SetStateAction, useRef, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { TransitionGroup } from 'react-transition-group';
import { alpha, Fade, Slide, styled } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { observer } from 'mobx-react-lite';
import { FOOTER_HEIGHT, HEADER_HEIGHT, MOBILE_MAX_WIDTH } from 'src/assets/static/constants';
import AppMessageListener from 'src/components/AppMessageListener';
import { LoadingScreen } from 'src/components/loading-screen';
import ScrollToTop from 'src/components/scroll-to-top/ScrollToTop';
import useResponsive from 'src/hooks/useResponsive';
import { useStores } from 'src/models';
import { AxiosInterceptor } from 'src/services/useAxiosInterceptor';

import CompanyInfo from '../company-info/CompanyInfo';
import Footer from '../footer/Footer';
import { Header, HeaderProps } from '../header';

export interface LayoutProps {
  setHeaderProps: Dispatch<SetStateAction<HeaderProps>>;
  headerHeight: number;
  footerHeight: number;
  mobileContainer: HTMLDivElement | null;
}

type TransitionType = 'slide' | 'fade';

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    type?: TransitionType;
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  switch (props.type) {
    case 'slide':
      return <Slide direction="left" ref={ref} {...props} />;
    case 'fade':
    default:
      return <Fade ref={ref} {...props} />;
  }
});

interface MobileLayoutProps {
  needHeader?: boolean;
  needFooter?: boolean;
  transitionType?: TransitionType;
}

function MobileLayout({
  needHeader = true,
  needFooter = true,
  transitionType = 'fade',
}: MobileLayoutProps) {
  const location = useLocation();
  const { appInfoStore } = useStores();
  const isDesktop = useResponsive('up', 'lg');
  const mobileContainerRef = useRef<HTMLDivElement>(null);
  const headerHeight = HEADER_HEIGHT + appInfoStore.safeAreaInsets.top;
  const footerHeight = needFooter ? FOOTER_HEIGHT + appInfoStore.safeAreaInsets.bottom : 0;
  const [headerProps, setHeaderProps] = useState<HeaderProps>({});

  return (
    <Container>
      {isDesktop && <CompanyInfo />}
      <MobileContainer ref={mobileContainerRef}>
        {needHeader && <Header {...headerProps} />}
        <TransitionGroup key={location.key} style={{ position: 'relative', flex: '1 0 0' }}>
          <Transition timeout={{ enter: 300, exit: 300 }} type={transitionType}>
            <MobileLayoutMain>
              <Outlet
                context={
                  {
                    setHeaderProps,
                    headerHeight,
                    footerHeight,
                    mobileContainer: mobileContainerRef.current,
                  } as LayoutProps
                }
              />
            </MobileLayoutMain>
          </Transition>
        </TransitionGroup>
        {needFooter && <Footer />}
      </MobileContainer>
      <ScrollToTop />
      <LoadingScreen />
      <AppMessageListener />
      <AxiosInterceptor />
      <BgContainer />
    </Container>
  );
}

export default observer(MobileLayout);

const MobileContainer = styled('section')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  flexBasis: MOBILE_MAX_WIDTH,
  width: MOBILE_MAX_WIDTH,
  minHeight: '100dvh',
  backgroundColor: theme.palette.background.default,
  zIndex: 2,

  // MOBILE_MAX_WIDTH보다 작을 경우 width를 100%로 변경
  [theme.breakpoints.down(MOBILE_MAX_WIDTH)]: {
    width: '100%',
  },
}));

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  height: 'auto',
  maxWidth: '100vw',
  minHeight: '100dvh',
  overscrollBehaviorY: 'auto',
  width: '100%',
  backgroundColor: theme.palette.common.black,
}));

const BgContainer = styled('div')(({ theme }) => ({
  position: 'fixed',
  width: '100%',
  height: '100vh',
  backgroundColor: theme.palette.common.black,
  backgroundImage: `radial-gradient(circle at left bottom, ${alpha(
    theme.palette.primary.main,
    0.15,
  )}, ${alpha(theme.palette.primary.main, 0)} 60%),
                    radial-gradient(circle at right top, ${alpha('#F6D9BB', 0.15)}, ${alpha(
    '#F6D9BB',
    0,
  )} 60%)`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: '100% 100%',
}));

const MobileLayoutMain = styled('main')(({ theme }) => ({
  height: '100%',
  backgroundColor: theme.palette.background.default,
}));
