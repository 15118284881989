import { useEffect, useMemo, useState } from 'react';
import { Button, ButtonProps, Card, Stack, styled, Typography, useTheme } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useStores } from 'src/models';

import PopperDialog from '../dialog/PopperDialog';

/**
 * ## CommonAlert 설명
 * - 기본 Alert 창으로 경고 및 정보 등 여러 상황에 알맞은 Alert를 제공합니다.
 * - alert store 통해 Alert 정보를 설정할 수 있습니다.
 */

const CommonAlert = () => {
  const theme = useTheme();
  const { alertStore } = useStores();
  const { open, category, title, content, hasCancelButton, btnLabel, closeAlert } = alertStore;

  // Alert 창의 카테고리별 theme 색상
  const categoryColors = useMemo(() => {
    return {
      error: theme.palette.error.main,
      warning: theme.palette.warning.main,
      info: theme.palette.info.main,
      success: theme.palette.success.main,
    };
  }, [
    theme.palette.error.main,
    theme.palette.info.main,
    theme.palette.success.main,
    theme.palette.warning.main,
  ]);

  // Alert 창의 카테고리별 아이콘 및 색상
  const [info, setInfo] = useState<{
    category: ButtonProps['color'];
    color: string;
  }>({
    category: 'secondary',
    color: categoryColors.info,
  });

  useEffect(() => {
    switch (category) {
      case 'success':
        setInfo({
          category: 'success',
          color: categoryColors.success,
        });
        break;
      case 'info':
        setInfo({
          category: 'info',
          color: categoryColors.info,
        });
        break;
      case 'warning':
        setInfo({
          category: 'warning',
          color: categoryColors.warning,
        });
        break;
      case 'error':
        setInfo({
          category: 'error',
          color: categoryColors.error,
        });
        break;
      case 'secondary':
      default:
        setInfo({
          category: 'secondary',
          color: theme.palette.secondary.main,
        });
        break;
    }
  }, [category]);

  // 모달 창 닫기
  const handleClose = () => {
    closeAlert();
  };

  // 확인 버튼 클릭 시
  const handleConfirm = () => {
    closeAlert();
    alertStore.invokeOnConfirm();
  };

  return (
    <PopperDialog open={open} onClose={handleClose} maxWidth="xs" sx={{ zIndex: 2000 }}>
      <StyledCard>
        {title && (
          <Stack direction="row" sx={{ justifyContent: 'center' }}>
            <Typography variant="h4" sx={{ margin: 'auto', fontWeight: '600', opacity: 0.9 }}>
              {title}
            </Typography>
          </Stack>
        )}
        {content && (
          <Stack
            direction="row"
            sx={{
              flexDirection: 'column',
              justifyContent: 'center',
              overflow: 'auto',
              maxWidth: 260,
            }}
          >
            {content.split('<br/>').map((value, index) => {
              return (
                <Typography
                  key={index}
                  fontSize={20}
                  lineHeight="150%"
                  textAlign="center"
                  sx={{
                    textAlign: 'center',
                    wordBreak: 'keep-all',
                  }}
                >
                  {value}
                  <br />
                </Typography>
              );
            })}
          </Stack>
        )}
        <Stack direction="row" sx={{ justifyContent: 'center' }} gap={2}>
          {hasCancelButton && (
            <StyledButton variant="outlined" color="secondary" size="medium" onClick={handleClose}>
              닫기
            </StyledButton>
          )}
          <StyledButton
            variant="contained"
            size="medium"
            color={info.category}
            onClick={handleConfirm}
          >
            {btnLabel ? btnLabel : '확인'}
          </StyledButton>
        </Stack>
      </StyledCard>
    </PopperDialog>
  );
};

export default observer(CommonAlert);

const StyledCard = styled(Card)(({ theme }) => ({
  width: 300,
  margin: 'auto',
  padding: `30px 20px 20px 20px`,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(5),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 600,
  flex: '1 0 0',
}));
