import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AxiosError, InternalAxiosRequestConfig } from 'axios';
import { useStores } from 'src/models';

import { TApiResponse } from './apiTypes';
import instance from './axios';
/**
 * # axios 인스턴스를 매개변수로 받는 useAxiosInterceptor 컴스텀 훅
 * - 요청과 응답 인터셉터를 설정하고 컴포넌트가 언마운트될 때 제거한다.
 */
const useAxiosInterceptor = () => {
  const { authStore } = useStores();
  const [init, setInit] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  // 요청을 처리하는 로직
  const handleRequest = (config: InternalAxiosRequestConfig<any>) => {
    const token = authStore.getAccessToken();
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  };

  // 응답을 처리하는 로직
  const handleResponse = async (response: TApiResponse<unknown>) => {
    const originalRequest = response.config;
    const { resultCode } = response.data;
    // ignore refresh auth error
    if (
      resultCode === 'F' &&
      (response.data.errorCode === '401' || response.data.errorCode === '4001') &&
      !originalRequest.url?.includes('/login/refresh')
    ) {
      const refreshToken = authStore.getRefreshToken();
      if (refreshToken) {
        const result = await authStore.refreshAccessToken(refreshToken);

        if (result.success) {
          // 토큰 저장
          result.access_token && authStore.setAccessToken(result.access_token);
          result.refresh_token && authStore.setRefreshToken(result.refresh_token);

          // Authorization 헤더에 새 액세스 토큰 설정
          originalRequest.headers['Authorization'] = `Bearer ${result.access_token}`;

          // 요청을 재시도
          return instance(originalRequest);
        } else {
          // refresh 토큰 갱신 실패 시 인증 초기화
          authStore.initAuth();
        }
      }

      // refresh 토큰 갱신 실패 시 인증 초기화
      authStore.initAuth();
      // auth guard가 없는 페이지에서만 로그인 페이지로 이동
      // TODO: 코드 개선 필요
      // 사용자 정보는 리로드 시 항상 불러오고 있어서 예외처리
      const hasAuthGuard = document.getElementById('auth');
      if (!hasAuthGuard && !originalRequest.url?.includes('/profile')) {
        navigate('/login', { replace: true, state: { from: pathname } });
      }
    }

    return response;
  };

  // 에러를 처리하는 로직
  const handleError = (error: AxiosError): Promise<AxiosError> => {
    return Promise.reject(error);
  };

  // 인터셉터를 설정
  const setupInterceptors = (): { requestInterceptor: number; responseInterceptor: number } => {
    const requestInterceptor = instance.interceptors.request.use(handleRequest, handleError);
    const responseInterceptor = instance.interceptors.response.use(handleResponse, handleError);

    setInit(true); // 인터셉터 설정 후 상태 변경

    return { requestInterceptor, responseInterceptor };
  };

  // 인터셉터를 제거
  const ejectInterceptors = (requestInterceptor: number, responseInterceptor: number): void => {
    instance.interceptors.request.eject(requestInterceptor);
    instance.interceptors.response.eject(responseInterceptor);
  };

  // 컴포넌트가 마운트될 때 인터셉터를 설정하고, 언마운트될 때 인터셉터를 제거한다.
  useEffect(() => {
    const { requestInterceptor, responseInterceptor } = setupInterceptors();

    return () => {
      ejectInterceptors(requestInterceptor, responseInterceptor);
    };
  }, [pathname]);

  return init;
};

/**
 * @title AxiosInterceptor
 * @description Axios 인터셉터를 설정하는 컴포넌트
 */
export const AxiosInterceptor = () => {
  const { authStore } = useStores();
  const isInterceptorReady = useAxiosInterceptor();

  const checkAuth = useCallback(async () => {
    if (isInterceptorReady) {
      await authStore.getUserInfo(); // 인터셉터가 준비된 후에만 API 호출
      authStore.setProp('isInitialized', true);
    }
  }, [authStore, isInterceptorReady]);

  useEffect(() => {
    checkAuth();
  }, [checkAuth]);

  return null;
};
