import { ElementType, lazy, Suspense } from 'react';
import { LoadingScreen } from 'src/components/loading-screen';

// components

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

export const Page403 = Loadable(lazy(() => import('../screens/common/Page403')));
export const Page404 = Loadable(lazy(() => import('../screens/common/Page404')));
export const Page500 = Loadable(lazy(() => import('../screens/common/Page500')));
export const ComingSoon = Loadable(lazy(() => import('../screens/common/ComingSoon')));
export const Maintenance = Loadable(lazy(() => import('../screens/common/Maintenance')));
export const Home = Loadable(lazy(() => import('../screens/home/Home')));

export const Login = Loadable(lazy(() => import('../screens/authentication/login/Login')));
export const Register = Loadable(lazy(() => import('../screens/authentication/register/Register')));
export const AlreadyExist = Loadable(
  lazy(() => import('../screens/authentication/register/AlreadyExist')),
);
export const AccountRecovery = Loadable(
  lazy(() => import('../screens/authentication/accountRecovery/AccountRecovery')),
);
export const FindId = Loadable(
  lazy(() => import('../screens/authentication/accountRecovery/FindId')),
);
export const FindPw = Loadable(
  lazy(() => import('../screens/authentication/accountRecovery/FindPw')),
);

export const Shop = Loadable(lazy(() => import('../screens/shop/Shop')));
export const Results = Loadable(lazy(() => import('../screens/results/Results')));
export const Events = Loadable(lazy(() => import('../screens/events/Events')));
export const MyPage = Loadable(lazy(() => import('../screens/my-page/MyPage')));

export const FaQ = Loadable(lazy(() => import('../screens/my-page/customer-service/FaQ')));
export const Notices = Loadable(
  lazy(() => import('../screens/my-page/customer-service/notices/Notices')),
);
export const NoticeDetail = Loadable(
  lazy(() => import('../screens/my-page/customer-service/notices/NoticeDetail')),
);
export const Inquiry = Loadable(
  lazy(() => import('../screens/my-page/customer-service/inquiry/Inquiry')),
);
export const MakeInquiry = Loadable(
  lazy(() => import('../screens/my-page/customer-service/inquiry/MakeInquiry')),
);
export const Setting = Loadable(lazy(() => import('../screens/my-page/setting/Setting')));
export const MyInfo = Loadable(lazy(() => import('../screens/my-page/setting/myInfo/MyInfo')));
export const NotificationSettings = Loadable(
  lazy(() => import('../screens/my-page/setting/NotificationSettings')),
);
export const Terms = Loadable(lazy(() => import('../screens/my-page/setting/terms/Terms')));
export const UserGuide = Loadable(lazy(() => import('../screens/user-guide/UserGuide')));
export const Withdrawal = Loadable(
  lazy(() => import('../screens/my-page/setting/myInfo/withdrawal/Withdrawal')),
);
export const Points = Loadable(lazy(() => import('../screens/my-page/points/Points')));
export const Coupons = Loadable(lazy(() => import('../screens/my-page/coupons/Coupons')));
export const Survey = Loadable(lazy(() => import('../screens/my-page/survey/Survey')));
export const ChangePw = Loadable(
  lazy(() => import('../screens/my-page/setting/myInfo/changePw/ChangePw')),
);
export const Goods = Loadable(lazy(() => import('../screens/goods/Goods')));
export const Cart = Loadable(lazy(() => import('../screens/cart/Cart')));
export const OrderList = Loadable(
  lazy(() => import('../screens/my-page/my-shopping/order/OrderList')),
);
export const OrderDetails = Loadable(
  lazy(() => import('../screens/my-page/my-shopping/order/OrderDetails')),
);
export const PaymentOrder = Loadable(lazy(() => import('../screens/payment-order/PaymentOrder')));
export const PaymentOrderSuccess = Loadable(
  lazy(() => import('../screens/payment-order-success/PaymentOrderSuccess')),
);
export const MyReview = Loadable(
  lazy(() => import('../screens/my-page/my-shopping/review/MyReview')),
);
export const MyQnA = Loadable(lazy(() => import('../screens/my-page/customer-service/qna/MyQnA')));
