import { TApiResponseData } from '../apiTypes';
import instance from '../axios';

export const getTerms = async (type: number) => {
  // type 1: 이용약관
  // type 2: 개인정보 처리방침
  // type 3: 사업자정보확인
  // type 4: 전자금융거래약관
  try {
    const url = `/common/v1/terms/${type}`;
    const response = await instance.get<TApiResponseData<any>>(url);

    // api 호출 성공
    if (response.data.resultCode === 'S' && response.data.data) {
      return response.data.data;
    }

    return [];
  } catch (error) {
    console.error(error);
  }
};

/**
 * 약관 상세정보 조회 (이전버전)
 */

/**
 * 해당 약관 버전 목록 조회
 */
