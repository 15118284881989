import { TGoodsOption } from 'src/@types';
import { ICart, IGoods } from 'src/@types/apiResponse';

import { TApiResponseData } from '../apiTypes';
import instance from '../axios';

const SUB_URL = '/common/v1/cart';

/**
 * 장바구니 정보 조회
 * TODO:: pagination 처리 필요
 */
export const getCart = async () => {
  try {
    const url = `${SUB_URL}`;
    const response = await instance.get<TApiResponseData<ICart>>(url, {
      params: {
        page: 1,
        size: 1000,
      },
    });

    // api 호출 성공
    if (response.data.resultCode === 'S' && response.data.data) {
      return {
        data: response.data.data,
      };
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * 장바구니 상품 추가
 */
export const addCartGoods = async (payload: TGoodsOption) => {
  try {
    const url = `${SUB_URL}/goods`;
    const response = await instance.post<TApiResponseData<IGoods>>(url, payload);

    // api 호출 성공
    if (response.data.resultCode === 'S') {
      return {
        success: true,
      };
    } else {
      return {
        success: false,
        message: response.data.errorMessage,
      };
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * 장바구니 상품 선택 목록 삭제
 */
export const deleteCartGoods = async (goodsSidList: number[]) => {
  try {
    const url = `${SUB_URL}/goods`;
    const response = await instance.delete<TApiResponseData<null>>(url, {
      params: {
        goodsSidList: goodsSidList.join(','),
      },
    });

    // api 호출 성공
    if (response.data.resultCode === 'S') {
      return {
        success: true,
      };
    }
  } catch (error) {
    console.error(error);
  }
};

/**
 * 장바구니 상품 옵션 수정
 */
export const updateCartGoods = async (payload: TGoodsOption) => {
  try {
    const url = `${SUB_URL}/goods/${payload.goodsSid}`;
    const response = await instance.put<TApiResponseData<IGoods>>(url, payload);

    // api 호출 성공
    if (response.data.resultCode === 'S') {
      return {
        success: true,
      };
    }
  } catch (error) {
    console.error(error);
  }
};
