import React from 'react';
import { useOutletContext } from 'react-router-dom';
import { Button, Snackbar } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { LayoutProps } from 'src/layouts';
import { useStores } from 'src/models';

function CommonSnackbar() {
  const { snackbarStore } = useStores();
  const { open, btnLabel, title, category, invokeOnClick, closeSnackbar } = snackbarStore;
  const { mobileContainer } = useOutletContext<LayoutProps>();

  const handleOnClick = () => {
    invokeOnClick();
    closeSnackbar();
  };

  return (
    <Snackbar
      style={{
        left: mobileContainer?.getBoundingClientRect().left,
        width: mobileContainer?.getBoundingClientRect().width,
        bottom: 200,
        display: 'flex',
        padding: '0 16px',
        justifyContent: 'center',
      }}
      sx={{
        '& .MuiPaper-root': {
          flexGrow: 0,
          maxWidth: '100%',
          '& .MuiSnackbarContent-message': {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
        },
      }}
      open={open}
      onClose={closeSnackbar}
      autoHideDuration={3000}
      message={title}
      action={
        btnLabel ? (
          <Button size="small" variant="contained" onClick={handleOnClick} color={category}>
            {btnLabel}
          </Button>
        ) : null
      }
    />
  );
}

export default observer(CommonSnackbar);
